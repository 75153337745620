import "./image-gallery.module.css";

import { Formatter, useMobile } from "@with-nx/hooks-n-helpers";
import {
  DesignedCard,
  DesignedCardSize,
  DesignedPagination,
} from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import dynamic from "next/dynamic";
import { createRef, useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

const AutoScroller = dynamic(() => import("../auto-scroller/auto-scroller"));

type ImageGalleryImage = {
  src: string | null;
  meta?: {
    copyright?: string;
    name?: string;
    date?: string;
    organization?: string;
  };
  pop?: boolean;
  press?: VoidFunction;
  size?: DesignedCardSize;
};

interface ImageGalleryProps {
  images: (string | null)[] | ImageGalleryImage[];
  per: number;
  autoScroll?: boolean;
  maxItemWidth?: number;
}

function calculateImageWidthPerPage(perPage: number, totalWidth: number) {
  const displayAmountOfImagesAsOverflow = 2;
  const totalImagesToDisplayPerPage = perPage + displayAmountOfImagesAsOverflow;
  return totalWidth / (totalImagesToDisplayPerPage - 1);
}

export const ImageGallery = (props: ImageGalleryProps) => {
  const { images, per, autoScroll, maxItemWidth } = props;
  const [width, _width] = useState<number>(0);
  const [current, _current] = useState<number>(1);
  const [pages] = useState<number>(images.length - 1);

  const ref = createRef<HTMLDivElement>();
  const mobile = useMobile();

  useEffect(() => {
    if (ref.current) {
      const _w = ref.current.offsetWidth;
      const calculate = calculateImageWidthPerPage(mobile ? 1 : per, _w);
      ref.current.scrollLeft = calculate;

      _width(
        Boolean(maxItemWidth) && calculate > maxItemWidth
          ? maxItemWidth
          : calculate
      );
    }
  }, [width, ref.current, mobile]);

  if (images.length === 0) {
    return null;
  }

  if (images.length >= 1 && images.length <= 4) {
    return (
      <div className="container">
        <Row gutter={mobile ? [8, 8] : [36, 36]}>
          {images.map((image, i) => {
            return (
              <Col
                key={i}
                xs={(() => {
                  if (i <= 1) {
                    if (images.length === 1) {
                      return 24;
                    }
                    if (images.length === 3) {
                      return 8;
                    }
                    return 12;
                  }
                  if (images.length === 3) {
                    return 8;
                  }
                  if (images.length === 4) {
                    return 12;
                  }
                  if (images.length === 5) {
                    return 8;
                  }
                  return 6;
                })()}
              >
                <DesignedCard
                  image={
                    typeof image === "string"
                      ? Formatter.image(image || "")
                      : Formatter.image(image?.src || "")
                  }
                  meta={
                    typeof image === "string"
                      ? undefined
                      : ([
                          ...(image?.meta?.copyright
                            ? [["Copyright Claim", image.meta.copyright]]
                            : []),
                          ...(image?.meta?.organization
                            ? [["Organization", image.meta.organization]]
                            : []),
                        ] as [string, string][])
                  }
                  size="16x9"
                  pop={true}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }

  const MARGIN_SIZE = mobile ? 8 : 36;

  const imagesList = images.map((image, i) => {
    return (
      <Box
        key={i}
        parse={`pl:${MARGIN_SIZE / 2} pr:${MARGIN_SIZE / 2} height:100%`}
        style={{ minWidth: width }}
        width={width}
      >
        <DesignedCard
          pop={
            typeof image === "string" || image?.pop === undefined
              ? true
              : image.pop
          }
          size={typeof image === "string" ? "square" : image?.size || "square"}
          image={
            typeof image === "string"
              ? Formatter.image(image || "")
              : Formatter.image(image?.src || "")
          }
          meta={
            typeof image === "string"
              ? undefined
              : ([
                  ...(image?.meta?.copyright
                    ? [["Copyright Claim", image.meta.copyright]]
                    : []),
                  ...(image?.meta?.organization
                    ? [["Organization", image.meta.organization]]
                    : []),
                ] as [string, string][])
          }
          inside={{
            press: typeof image === "string" ? undefined : image?.press,
          }}
        />
      </Box>
    );
  });

  return (
    <Box>
      <div
        className="--gallery --no-scrollbar"
        ref={ref}
        onScroll={(event) => {
          const round = Math.round(event.currentTarget.scrollLeft / width);
          _current(round);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
          marginLeft: -(MARGIN_SIZE / 2),
          marginRight: -(MARGIN_SIZE / 2),
          paddingBottom: "32px",
        }}
      >
        <Box style={{ minWidth: width / 2 }} width={width / 2}></Box>

        {autoScroll ? (
          <AutoScroller duration={images.length * 4}>{imagesList}</AutoScroller>
        ) : (
          <>{imagesList}</>
        )}

        <Box style={{ minWidth: width / 2 }} width={width / 2}></Box>
      </div>

      {!autoScroll && (
        <Box parse="mt:24" css="d:flex a:center j:center">
          <DesignedPagination
            press={(value) => {
              if (ref.current) {
                ref.current.scrollTo({
                  left: value * width,
                  behavior: "smooth",
                });
              }
            }}
            mode="lines"
            size={pages}
            value={current}
          />
        </Box>
      )}
    </Box>
  );
};

export default ImageGallery;
